import IGroup from "../models/groups/IGroup";
import IUser from "../models/user/IUser";
import CreateGraphClient, { getAccessToken } from "./MicrosoftGraphClient";
import networkService from "./networkService";

class AccountService {

    public async getAccount() : Promise<IUser> {
        const accessToken = await getAccessToken();
        const client = CreateGraphClient(accessToken!);

        try {
            return await client.me() as IUser;
        } catch (error) {
            throw error;
        }
    }

    public async getProfilePicture(): Promise<string> {
        const accessToken = await getAccessToken();
        const client = CreateGraphClient(accessToken!);

        try {
            return await client.myPhoto() as string;
        } catch (error) {
            throw error;
        }
    }

    public async getGroupMemberships(): Promise<IGroup[]> {
        const accessToken = await getAccessToken();
        const client = CreateGraphClient(accessToken!);

        try {
            return await client.myMemberships("microsoft.graph.group") as IGroup[];
        } catch (error) {
            throw error;
        }
    }

    /**
     * @description Check if the current user is an approver
     */
    async isApprover() {
        const result = await networkService.get<boolean>("user/approver");

        if (result.isSuccessful) {
            return result.data as boolean;
        }

        throw result.data;
    }
}

const accountService = new AccountService();
export default accountService;